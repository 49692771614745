import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Wave, { WaveType } from "../components/ui/wave"
import { PageType } from "./about"
import TopSection from "../components/referral/TopSection"
import { BlogPostType } from "../components/blog/blogPostSection"
import Steps from "../components/common/Steps"
import Body from "../components/referral/Body"
import { Locale } from "../common/appContext"

const ReferralPage = ({ path }) => {
  const {
    graphCmsPage,
    allGraphCmsBlogPost,
    graphCmsBlogPost,
  } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckyps0dw8a8ks0d044sm3oerk:sv:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }

      allGraphCmsBlogPost(
        sort: { order: ASC, fields: createdAt }
        filter: {
          stage: { eq: PUBLISHED }
          blogCategories: {
            elemMatch: {
              id: { eq: "BlogCategory:ckz1ebckwuqkl0c44ggxvuaj9:PUBLISHED" }
            }
          }
          locale: { eq: sv }
        }
      ) {
        nodes {
          blogCategories {
            category
            color
            slug
          }
          body {
            html
          }
          heading
          summary
          slug
          picture {
            alt
            id
            url
            localFile {
              childrenImageSharp {
                gatsbyImageData(width: 596, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      graphCmsBlogPost(
        blogCategories: {
          elemMatch: { category: { eq: "techAgentsBodyText" } }
        }
        locale: { eq: sv }
      ) {
        body {
          html
        }
      }
    }
  `)

  const data: PageType = graphCmsPage
  const posts: BlogPostType[] = allGraphCmsBlogPost.nodes
  const post: BlogPostType = graphCmsBlogPost

  return (
    <Layout
      initialLogoWhite
      initialTextColorThemeWhite
      path={path}
      locale={Locale.sv}
    >
      <SEO title="techAgents | cruto.io" meta={data.seo} />
      <div>
        <TopSection {...data} />
        <section className="container">
          <Steps posts={posts} />
        </section>
        <Body html={post.body.html} />
      </div>
      <Wave className={"u-c-orange"} type={WaveType.v5} />
    </Layout>
  )
}

export default ReferralPage
